import "../css/styles.css";
import { useEffect, useState } from "react";
import GetNote from "./GetNote";
import NewNote from "./NewNote";
import Footer from "./footer"
import Navbar from "./Navbar";

import { CircleSpinnerOverlay } from "react-spinner-overlay";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useParams } from "react-router-dom";

function HomePage() {
  const [activeTab, setActiveTab] = useState(1);

  const [activeoverlay, setactiveoverlay] = useState(false);

  return (
    <div>
      <Navbar></Navbar>
     
      <main role="main" className="container">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 1 ? " active" : ""}`}
              data-bs-toggle="tab"
              href="#tab1"
            >
              Create Note
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 2 ? " active" : ""}`}
              data-bs-toggle="tab"
              href="#tab2"
            >
              Get Note
            </a>
          </li>
        </ul>

        <div className="tab-content mt-2">
          <div
            className={`tab-pane fade ${activeTab === 1 ? "show active" : ""}`}
            id="tab1"
          >
            <NewNote />
          </div>
          <div
            className={`tab-pane fade ${activeTab === 2 ? "show active" : ""}`}
            id="tab2"
          >
            <GetNote
              setactiveoverlay={setactiveoverlay}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      </main>
      <Footer></Footer>

      <CircleSpinnerOverlay
        loading={activeoverlay}
        overlayColor="rgba(0,153,255,0.2)"
      />

      <ToastContainer toastId="GetNotes_Toaster" />
    </div>
  );
}

export default HomePage;
