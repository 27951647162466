import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import Navbar from "./Navbar";
import QRCode from "react-qr-code";

function ContactUs() {
  useEffect(() => {
    toast.dismiss();
    //console.log("Toast is dismissing");
  }, []);

  const [Name, SetName] = useState("");
  const [Email, SetEmail] = useState("");
  const [Message, SetMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let isValid = true;

    if (Name.trim() === "") {
      isValid = false;
    }

    if (Email.trim() === "") {
      isValid = false;
    }
    if (Message.trim() === "") {
      isValid = false;
    }
    return isValid;
  };

  const handleOnNameChanged = (e) => {
    SetName(e.target.value);
  };
  const handleOnEmailChanged = (e) => {
    SetEmail(e.target.value);
  };
  const handleMessageOnChange = (e) => {
    SetMessage(e.target.value);
  };

  const handleClick = async (e) => {
    if (!validateForm()) return;

    setLoading(true);
    const token = await fetchToken();
    const responseData = await saveData(token);

    setLoading(false);

    if (responseData) {
      toast.success("Thank you for contacting us  !", {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => (window.location.href = "https://notepadely.com/"),
      });
    }
  };

  const fetchToken = async () => {
    try {
      console.log(process.env.REACT_APP_URL);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/token`,
        {
          username: process.env.REACT_APP_USER,
          password: process.env.REACT_APP_PWD,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.token;
    } catch (error) {
      console.error("Error fetching token:", error);
      return null;
    }
  };

  const saveData = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/ContactForm`,
        {
          Name: Name,
          Email: Email,
          Message: Message,
          IP: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  return (
    <>
      <main role="main" className="container">
        <Navbar></Navbar>
<div className="col-md-6">
<h3>Contact Form</h3>

<label htmlFor="Name" className="form-label">
  Name
</label>
<input
  type="text"
  onChange={handleOnNameChanged}
  value={Name}
  id="Name"
  className="form-control"
  style={{ padding: "5px" }}
/>
{Name.trim() === "" && <div className="error">Name is required</div>}

<label htmlFor="Email" className="form-label">
  Email
</label>
<input
  type="email"
  onChange={handleOnEmailChanged}
  value={Email}
  id="Email"
  className="form-control"
  style={{ padding: "5px" }}
/>
{Email.trim() === "" && <div className="error">Email is required</div>}

<label htmlFor="Message" className="form-label">
  Message
</label>
<textarea
  onChange={handleMessageOnChange}
  value={Message}
  className="form-control"
  id="Message"
  rows="5"
></textarea>
{Message.trim() === "" && <div className="error">Message is required</div>}

<button
  disabled={loading}
  onClick={handleClick}
  className="btn btn-primary"
  type="button"
  style={{ marginTop: "10px" }}
>
  {loading ? "Loading..." : "Submit"}
</button>

</div>

    
      </main>
      <Footer></Footer>
      <ToastContainer />
    </>
  );
}

export default ContactUs;
