import Navbar from "./Navbar";
import Footer from "./footer";

function About() {
  return (
    <>
      <Navbar></Navbar>

      <div className="content-wrapper">
                
        <h1>About Us</h1>
        <p>
          Welcome to Notepadely, your go-to platform for seamless note-taking
          and sharing across all your devices. At Notepadely, we are dedicated
          to making your digital note-taking experience as effortless and
          efficient as possible. Whether you're jotting down quick ideas,
          organizing detailed plans, or sharing important information,
          Notepadely is here to help you stay connected and productive.
        </p>

        <h2>Our Mission</h2>
        <p>
          Our mission is simple: to provide a reliable, user-friendly platform
          that enhances your ability to manage and share notes across multiple
          devices. We understand the importance of having your notes accessible
          anytime, anywhere, and our goal is to make that process as
          straightforward as possible.
        </p>

        <h2>How We Help You Share Notes</h2>
        <p>
          We offer several convenient options to share your notes across
          devices, ensuring you can always access your information when you need
          it.
        </p> 
        <p>
          Using Notepadely, you can easily share your notes by copying and
          pasting the unique code generated for each note. This code can be
          pasted directly into the Notepadely website on any device, providing
          instant access to your information. It's a quick and easy way to keep
          your notes synchronized across all your devices.
        </p>

       
        <h2>Why Choose Notepadely?</h2>
        <ul>
          <li>
            <strong>User-Friendly Interface:</strong> Our platform is designed
            with simplicity in mind, making it easy for anyone to use.
          </li>
          <li>
            <strong>Cross-Device Compatibility:</strong> Access your notes from
            any device, whether it's a computer, tablet, or smartphone.
          </li>
          <li>
            <strong>Secure and Reliable:</strong> Your notes are stored
            securely, ensuring your information is always safe and accessible.
          </li>
          <li>
            <strong>Efficient Sharing Options:</strong> Choose the sharing
            method that works best for you, whether it's copying a code, sending
            a URL, or scanning a QR code.
          </li>
        </ul>

        <p>
          At Notepadely, we are committed to enhancing your note-taking
          experience with innovative features and reliable service. Thank you
          for choosing Notepadely, where your notes are always at your
          fingertips.
        </p>

        <p>
          Explore the many ways Notepadely can improve your productivity and
          keep your notes organized. Start using Notepadely today and discover
          the difference!
        </p>
      </div>

      <Footer></Footer>
    </>
  );
}

export default About;
