import Navbar from "./Navbar";
import Footer from "./footer";

function Disclaimer() {
  return (
    <>
      <Navbar></Navbar>

      <div className="content-wrapper">
        <h2>Discalimer for Notepadely</h2>
        <p>
          All the information on this website – Notepadely.com – is
          published in good faith and for general information purpose only.
          Notepadely.com does not make any warranties about the
          completeness, reliability and accuracy of this information.
        </p>
        <p>
          Any action you take upon the information you find on this website
          (Notepadely.com), is strictly at your own risk. Notepadely.com
          will not be liable for any losses and/or damages in connection with
          the use of our website.
        </p>
        <p>
          From our website, you can visit other websites by following hyperlinks
          to such external sites. While we strive to provide only quality links
          to useful and ethical websites, we have no control over the content
          and nature of these sites.
        </p>
        <p>
          These links to other websites do not imply a recommendation for all
          the content found on these sites. Site owners and content may change
          without notice and may occur before we have the opportunity to remove
          a link which may have gone ‘bad’. Please be also aware that when you
          leave our website, other sites may have different privacy policies and
          terms which are beyond our control.
        </p>
        <p>
          Please be sure to check the   <a href="https://notepadely.com/privacy-policy/">
            Privacy Policies
          </a>
          of these sites as well as their “Terms of Service” before engaging in
          any business or uploading any information.
        </p>
        <p>
          Our Privacy Policy was created by the Privacy Policy Generator.
          Consent By using our website, you hereby consent to our disclaimer and
          agree to its terms.
        </p>
        <p>
          Update Should we update, amend or make any changes to this document,
          those changes will be prominently posted here. Create a disclaimer
          page for   <a href="https://notepadely.com/">NotePadely.com</a>
        </p>
      </div>

      <Footer></Footer>
    </>
  );
}

export default Disclaimer;
