import Navbar from "./Navbar";
import Footer from "./footer";

function NoteFound() {
  return (
    <>
      <Navbar></Navbar>

      <div className="content-wrapper">
        <h1>Page not Not Found</h1>
      </div>

      <Footer></Footer>
    </>
  );
}

export default NoteFound;
