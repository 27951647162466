import Navbar from "./Navbar";
import Footer from "./footer"

function AboutUs() {
  return (
    <>
      <Navbar></Navbar>

      <div className="content-wrapper">
        <h1>Options to Share Notes Across Devices</h1>

        <div className="option-card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                Option 1: Copy and Paste the Code in Notepadely Website
              </h5>
              <p className="card-text">
                By using Notepadely website, you can easily share your notes
                across devices by copying and pasting the code. Notepadely
                provides a convenient platform to store and access your notes
                from anywhere.
              </p>
            </div>
          </div>
        </div>

        <div className="option-card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Option 2: Copy and Paste the URL</h5>
              <p className="card-text">
                Another way to share your notes is by copying the URL. You can
                simply copy the URL of the note you want to share and send it to
                another device. The recipient can then open the URL in their
                browser to access the notes.
              </p>
            </div>
          </div>
        </div>

        <div className="option-card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                Option 3: Scan the QR Code in Another Device
              </h5>
              <p className="card-text">
                If you prefer a more convenient method, you can use the QR code.
                By scanning the QR code with a QR code scanner on another
                device, you can quickly access and share your notes. This
                eliminates the need for manual copying and pasting or typing of
                URLs.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <Footer></Footer>
    </>
  );
}

export default AboutUs;
