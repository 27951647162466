import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "react-qr-code";

function NewNote() {
  useEffect(() => {
    toast.dismiss();
    //console.log("Toast is dismissing");
  }, []);

  const [Title, SetTitle] = useState("");
  const [Description, SetDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [noteCode, setNoteCode] = useState("");
  const [noteUrl, setNoteUrl] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const validateForm = () => {
    let isValid = true;

    if (Title.trim() === "") {
      isValid = false;
    }

    if (Description.trim() === "") {
      isValid = false;
    }

    return isValid;
  };

  const handleOnTitleChanged = (e) => {
    SetTitle(e.target.value);
  };
  const handleDescriptionOnChange = (e) => {
    SetDescription(e.target.value);
  };

  const handleClick = async (e) => {
    if (!validateForm()) return;

    setLoading(true);
    const token =process.env.REACT_APP_TOKEN;// await fetchToken();

    let responseData=null;
    if (noteCode === "") {
      responseData = await saveData(token);
    } else {
      responseData = await editData(token);
    }

    setLoading(false);

    if (responseData) {
      const noteCode = responseData.noteCode;
      const noteUrl = "https://notepadely.com/" + noteCode;
      setNoteCode(noteCode);
      setNoteUrl(noteUrl);
      console.log(process.env.REACT_DOMAIN);
      toast.success("Notes saved successfully  !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const fetchToken = async () => {
    try {
      console.log(process.env.REACT_APP_URL);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/token`,
        {
          username: process.env.REACT_APP_USER,
          password: process.env.REACT_APP_PWD,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.token;
    } catch (error) {
      console.error("Error fetching token:", error);
      return null;
    }
  };

  const saveData = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/CreateNotes`,
        {
          Title: Title,
          Description: Description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const editData = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/UpdateNotes`,
        {
          code: noteCode,
          title: Title,
          description: Description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleCopy = () => {
    const input = document.getElementById("noteUrlInput");
    input.select();
    document.execCommand("copy");
    input.blur();
    setIsCopied(true);
  };

  return (
    <>
      <h3>Create new note</h3>

      <label htmlFor="exampleFormControlTextarea1" className="form-label">
        Notes Title
      </label>
      <input
        type="text"
        onChange={handleOnTitleChanged}
        value={Title}
        id="TitleText"
        className="form-control"
        style={{ padding: "5px" }}
      />
      {Title.trim() === "" && <div className="error">Title is required</div>}

      <label htmlFor="exampleFormControlTextarea1" className="form-label">
        Notes
      </label>
      <textarea
        onChange={handleDescriptionOnChange}
        value={Description}
        className="form-control"
        id="exampleFormControlTextarea1"
        rows="10"
      ></textarea>
      {Description == "" && <div className="error">Notes Required</div>}

      <button
        disabled={loading}
        onClick={handleClick}
        className="btn btn-primary"
        type="button"
        style={{ marginTop: "10px" }}
      >
        {loading ? "Loading..." : "Save"}
      </button>
      {noteCode && (
        <div
          className="card"
          style={{ marginTop: "20px", marginBottom: "80px" }}
        >
          <div className="card-body">
            <p className="card-text">Note Code: {noteCode}</p>

            <div className="form-group">
              <label htmlFor="noteUrlInput">Note URL</label>
              <div className="input-group">
                <input
                  id="noteUrlInput"
                  type="text"
                  className="form-control"
                  value={noteUrl}
                  readOnly
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={handleCopy}
                  >
                    <span>{isCopied ? "Copied" : "Copy"}</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="text-center mt-5 mb-5">
              <QRCode value={noteUrl} size={150} />
            </div>

            <div className="Steps">
              <h3>Below options to share the notes across the devices</h3>
              <h4>Options 1 : </h4>
              <p>Copy and Paste the code in notepadely website </p>
              <h4>Options 2 : </h4>
              <p>Copy and Paste the url </p>

              <h4>Options 3 : </h4>
              <p>Scan the qr code in another device </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default NewNote;
