import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "react-qr-code";
import he from "he"; // Import the HTML encoding library

import { useParams } from "react-router-dom";

function GetNote({ setActiveTab,setactiveoverlay })
{
  const { noteid } = useParams();

  const [Title, SetTitle] = useState("");
  const [Description, SetDescription] = useState("");

  const [LoadingSubmitCode, setLoadingSubmitCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [noteCode, setNoteCode] = useState("");
  const [noteUrl, setNoteUrl] = useState("");
  const [isCopied, setIsCopied] = useState(false);
 

  const [Code, setCode] = useState(noteid || "");

  useEffect(() => {
    toast.dismiss();
    //Fetch data based on the noteid
    if (noteid) {

      
      setactiveoverlay(true);

       


      let formattedcode = he.encode(noteid);

      setCode(formattedcode);

      //console.log(formattedcode);

      handleCodebuttonClick();

      setActiveTab(2);
      //setactiveoverlay(false);

    }
  }, []);

  //on code text change

  const handleOnCodeChanged = (event) => {
    setCode(event.target.value);
  };

  const handleOnTitleChanged = (event) => {
    SetTitle(event.target.value);
  };
  const handleDescriptionOnChange = (event) => {
    SetDescription(event.target.value);
  };

  const handleCodebuttonClick = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (Code == "") {
      alert("Please enter the code");
      return;
    }
    setLoadingSubmitCode(true);

    try {
      const token =process.env.REACT_APP_TOKEN; //await fetchToken();
      const responseData = await fetchData(token);

      if (responseData === null || responseData.length === 0) {
        alert("Invalid code !");
        setActiveTab(1);
        setactiveoverlay(false);
      } else {
        SetTitle(responseData[0].title);
        SetDescription(responseData[0].description);
        setactiveoverlay(false);
      }

      setLoadingSubmitCode(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  const fetchToken = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/token`,
        {
          username: process.env.REACT_APP_USER,
          password: process.env.REACT_APP_PWD,
        }
      );
      return response.data.token;
    } catch (error) {
      console.error("Error fetching token:", error);
      return null;
    }
  };

  const fetchData = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/GetNotes`,
        {
          code: Code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleClick = async (e) => {
    setLoading(true);
    const token = process.env.REACT_APP_TOKEN;//await fetchToken();
    const responseData = await editData(token);
    setLoading(false);

    //console.log(responseData);
    if (responseData) {
      const noteCode = responseData.noteCode;

      const noteUrl = "https://notepadely.com/" + noteCode;
      setNoteCode(noteCode);
      setNoteUrl(noteUrl);
      toast.success("Notes saved successfully  !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const editData = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/UpdateNotes`,
        {
          code: Code,
          title: Title,
          description: Description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };
  const handleCopy = () => {
    const input = document.getElementById("noteUrlInput");
    input.select();
    document.execCommand("copy");
    input.blur();
    setIsCopied(true);
  };

  return (
    <div className="genotecontainer">
      {!noteid && (
        <>
          <h3 style={{ marginBottom: "20px" }}>Get existing note by code</h3>

          <div className="form-group mb-5">
            <label htmlFor="codeInput" style={{ marginRight: "10px" }}>
              Enter Code:
            </label>

            <input
              type="text"
              onChange={handleOnCodeChanged}
              id="codeInput"
              value={Code}
              style={{ padding: "5px" }}
            />
            <button
              disabled={loading}
              onClick={handleCodebuttonClick}
              type="button"
              style={{
                padding: "5px 10px",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
            >
              {LoadingSubmitCode ? "Loading..." : "Submit"}
            </button>
          </div>
        </>
      )}

      

      <label htmlFor="exampleFormControlTextarea1" className="form-label">
        Notes Title
      </label>
      <input
        type="text"
        onChange={handleOnTitleChanged}
        value={Title}
        id="TitleText"
        className="form-control"
        style={{ padding: "5px" }}
      />

      <label htmlFor="exampleFormControlTextarea1" className="form-label">
        Notes
      </label>
      <textarea
        onChange={handleDescriptionOnChange}
        value={Description}
        className="form-control"
        id="exampleFormControlTextarea1"
        rows="10"
      ></textarea>
      <button
        disabled={loading}
        onClick={handleClick}
        className="btn btn-primary"
        type="button"
        style={{ marginTop: "10px" }}
      >
        {loading ? "Loading..." : "Save"}
      </button>

      {noteCode && (
        <div
          className="card"
          style={{ marginTop: "20px", marginBottom: "80px" }}
        >
          <div className="card-body">
            <p className="card-text">Note Code: {noteCode}</p>

            <div className="form-group">
              <label htmlFor="noteUrlInput">Note URL</label>
              <div className="input-group">
                <input
                  id="noteUrlInput"
                  type="text"
                  className="form-control"
                  value={noteUrl}
                  readOnly
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={handleCopy}
                  >
                    <span>{isCopied ? "Copied" : "Copy"}</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="text-center mt-5 mb-5">
              <QRCode value={noteUrl} size={150} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default GetNote;
