import logo from "../images/notebook.png";
function Footer() {
  return (
     
    <footer className="footer">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-md-6 d-flex align-items-center">
          <ul className="navbar-nav flex-row">
            <li className="nav-item me-3">
              <a className="nav-link text-white active" aria-current="page" href="/about-us">About Us</a>
            </li>
            <li className="nav-item me-3">
              <a className="nav-link text-white" href="/disclaimer">Disclaimer</a>
            </li>
            <li className="nav-item me-3">
              <a className="nav-link text-white" href="/privacy-policy">Privacy Policy</a>
            </li>
            <li className="nav-item me-3">
              <a className="nav-link text-white" href="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="col-md-6 d-flex justify-content-md-end justify-content-center align-items-center">
          <p className="mb-0">&copy; 2023 NotePadely. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>



  );
}

export default Footer;
